import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  RadioTile,
  TileGroup
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import '../FormMultisteps.scss';
import './FormStep3.scss';
import { FormStateAttribute } from '../FormMultisteps';
import { useEffect, useState } from 'react';
import VideoEmbed from '../../Vimeo/Vimeo'; 


const FormStep3 = ({ formState, prevStep, nextStep,handleChange }: any) => {
  const { t } = useTranslation();
  var videoLink = 'https://share.synthesia.io/embeds/videos/df2b37ae-3ae6-480d-8afc-db3e03652569';
  var helpStringBuilder = formState.AGC;
  const [CalcMethodInvalid, setCalcMethodInvalid] = useState(true);
  const [hasAttemptedNextStep, setHasAttemptedNextStep] = useState(false);
  const [showInvalidBorder, setShowInvalidBorder] = useState(false); // State to control the border color
  const [videoKey, setVideoKey] = useState(0); // Add videoKey state

  const handleNextStep = (event: any) => {
    setHasAttemptedNextStep(true);
    if (CalcMethodInvalid) {
      event.preventDefault();
      setShowInvalidBorder(true); // Show invalid border
    } else {
      setShowInvalidBorder(false); // Hide invalid border
      nextStep(event); // proceed to the next step
    }
  };

  useEffect(() => {
    if (formState.calculationMethod) {
      setCalcMethodInvalid(false);
    }
  }, [formState.calculationMethod]);

  const emissionCalculationOptions = () => {
    return (
      <TileGroup
        name="emissionCalculationMethod"
        valueSelected={formState.calculationMethod}
        onChange={handleCalMethod}
      >

        <RadioTile   id="calculated-method"
        name="calculationMethod" // This should match the key in formState
        value="calculated"
        className="radiotile"
        style={{
          border: showInvalidBorder ? '2px solid red' : '2px solid transparent', // Apply border when invalid
        }}
        >
          <strong>{t('survey.step3.radioTile.title')}</strong> <br/> {t('survey.step3.radioTile.description')}
        </RadioTile>
      </TileGroup>
    );
  };

  const handleCalMethod = (event: any) => {
    const calc: FormStateAttribute = {
      name: 'calculationMethod',
      value: event,
    };
    handleChange(calc);
    setCalcMethodInvalid(false);
    setShowInvalidBorder(false); // Hide invalid border
  };
  
  const showHelperTextAccordingToAGC = () => {
    switch (formState.AGC) {
      case 'Sintered Ore':
      case 'Pig iron':
      case 'Cement clinker':
      case 'FeMn Ferro-Manganese':
      case 'FeCr Ferro-Chromium':
      case 'FeNi Ferro Nickel':
      case 'Direct reduced iron':
      case 'Crude Steel':
      case 'Iron or steel products':
      case 'Cement':
      case 'Urea':
      case 'Aluminous cement':
      case 'Mixed fertilizers':
      case 'Ammonia':
      case 'Aluminium products':
      case 'Unwrought aluminium':
      case 'Electricity (export to EU)':
      case 'Nitric acid':
      case 'Calcined clays':
      case 'Hydrogen':
        return (
          <Accordion>
            <AccordionItem title={t('survey.step3.faq.accordion1.title')}>
              <p>
                {' '}
                {t('survey.step3.faq.accordion1.text')} {helpStringBuilder}{' '}
                {t('survey.step3.faq.accordion2.text')}
              </p>
            </AccordionItem>
            <AccordionItem title={t('survey.step3.faq.accordion2.title')}>
              <p>
                <div>
                <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967501"
                  title="CBAM Academy - Aggregated Goods Categories"
                  width="100%"
                  height="auto"
                  key={videoKey.toString()} // Convert videoKey to string
                />
                </div>
              </p>
            </AccordionItem>
            <AccordionItem title={t('survey.step3.faq.accordion4.title')}>
              <p>
                {t('survey.step3.faq.accordion4.text')}
              </p>
            </AccordionItem>
            <AccordionItem title={t('survey.step3.faq.accordion5.title')}>
              <p>
                <div>
                <VideoEmbed
                  src="https://player.vimeo.com/video/990237903"
                  title="CBAM Academy - Calculating Emissions"
                  width="100%"
                  height="auto"
                  key={videoKey.toString()} // Convert videoKey to string
                />
                </div>
              </p>
            </AccordionItem>
            <AccordionItem title={t('survey.step3.faq.accordion3.title')}>
              <p>{t('survey.step3.faq.accordion3.text')}</p>
            </AccordionItem>
          </Accordion>
        );
    }
    return <></>;
  };

  return (
    <>
      <Form onSubmit={handleNextStep} id="step3" className="form-step">
        <div className="grid-container-element">
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h4>{t('survey.step3.name')}</h4>
              </Column>
              <Column lg={16} md={8} sm={4}>
                <Table aria-label="survey table">
                  <TableHead>
                    <TableRow>
                      <TableHeader>{t('common.parameterTable.CNNumber')}</TableHeader>
                      <TableHeader>{t('common.parameterTable.CNName')}</TableHeader>
                      <TableHeader>{t('survey.step3.AGC.labelText')} </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{formState.CNNumber}</TableCell>
                      <TableCell>{formState.CNName}</TableCell>
                      <TableCell>{formState.AGC}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Column>
              <Column lg={16} md={8} sm={4}>
  <h5>{t('survey.step3.radioTile.title')}</h5>
  {emissionCalculationOptions()}
</Column>
            </Grid>
          </div>
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h5 className="faq">{t('survey.step2.faq.title')}</h5>
                {showHelperTextAccordingToAGC()}
              </Column>
            </Grid>
          </div>
        </div>
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.next')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default FormStep3;
